*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body {
  font-family: 'sf_proregular', sans-serif;
  font-size: 16px;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  /* overflow: hidden; */
}

img {
  height: auto;
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #cfd2d8;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7f879a;
}

::-webkit-scrollbar-thumb:active {
  background: #596581;
}

::-webkit-scrollbar-track {
  background: #eceff1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #eceff1;
}

::-webkit-scrollbar-track:active {
  background: #cfd2d8;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.ws-pl {
  white-space: pre-line;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'AgroPack' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** DON'T DELETE IT **/
.icon-swine:before {
  content: '\E92F';
}
.icon-broiler:before {
  content: '\E913';
}
.icon-broiler-alt:before {
  content: '\E967';
}
/** \ please, don't **/

.icon-apple-store:before {
  content: '\E941';
}
.icon-archive:before {
  content: '\E900';
}
.icon-arrow-down:before {
  content: '\E901';
}
.icon-arrow-left:before {
  content: '\E902';
}
.icon-arrow-right:before {
  content: '\E903';
}
.icon-arrow-up:before {
  content: '\E904';
}
.icon-bell-disabled:before {
  content: '\E905';
}
.icon-bell-enabled:before {
  content: '\E906';
}
.icon-bold:before {
  content: '\E975';
}
.icon-book:before {
  content: '\E907';
}
.icon-bootprint:before {
  content: '\E976';
}
.icon-bottle:before {
  content: '\E942';
}
.icon-boxes:before {
  content: '\E943';
}
.icon-bug:before {
  content: '\E977';
}
.icon-calculator:before {
  content: '\E908';
}
.icon-calendar:before {
  content: '\E909';
}
.icon-calendar-alt:before {
  content: '\E96D';
}
.icon-camera:before {
  content: '\E97E';
}
.icon-capsules:before {
  content: '\E944';
}
.icon-chart-analytics:before {
  content: '\E90A';
}
.icon-chart-bar:before {
  content: '\E90B';
}
.icon-chart-line:before {
  content: '\E90C';
}
.icon-chart-pie:before {
  content: '\E90D';
}
.icon-check:before {
  content: '\E90E';
}
.icon-check-circle:before {
  content: '\E945';
}
.icon-check-double:before {
  content: '\E988';
}
.icon-check-square:before {
  content: '\E946';
}
.icon-chevron-down:before {
  content: '\E90F';
}
.icon-chevron-left:before {
  content: '\E910';
}
.icon-chevron-right:before {
  content: '\E911';
}
.icon-chevron-up:before {
  content: '\E912';
}
.icon-chicken:before {
  content: '\E913';
}
.icon-chicken-alt:before {
  content: '\E967';
}
.icon-chlorine:before {
  content: '\E968';
}
.icon-clip:before {
  content: '\E978';
}
.icon-clipboard:before {
  content: '\E947';
}
.icon-clipboard-user:before {
  content: '\E948';
}
.icon-clock:before {
  content: '\E914';
}
.icon-close:before {
  content: '\E915';
}
.icon-close-circle:before {
  content: '\E916';
}
.icon-close-square:before {
  content: '\E949';
}
.icon-cloud-sun:before {
  content: '\E94A';
}
.icon-cog:before {
  content: '\E917';
}
.icon-comment:before {
  content: '\E918';
}
.icon-corn:before {
  content: '\E94B';
}
.icon-counter:before {
  content: '\E969';
}
.icon-disease:before {
  content: '\E97F';
}
.icon-dolly:before {
  content: '\E919';
}
.icon-download:before {
  content: '\E91A';
}
.icon-drop:before {
  content: '\E94C';
}
.icon-drop-rain:before {
  content: '\E94D';
}
.icon-edit:before {
  content: '\E91B';
}
.icon-edit-alt:before {
  content: '\E94E';
}
.icon-ellipsis-v:before {
  content: '\E91C';
}
.icon-exclamation-circle:before {
  content: '\E91D';
}
.icon-exclamation-triangle:before {
  content: '\E91E';
}
.icon-file:before {
  content: '\E94F';
}
.icon-filter:before {
  content: '\E91F';
}
.icon-filter-alt:before {
  content: '\E920';
}
.icon-flask:before {
  content: '\E950';
}
.icon-flock:before {
  content: '\E921';
}
.icon-folder:before {
  content: '\E980';
}
.icon-folder-tree:before {
  content: '\E982';
}
.icon-folders:before {
  content: '\E981';
}
.icon-globe:before {
  content: '\E951';
}
.icon-google-play:before {
  content: '\E952';
}
.icon-heart:before {
  content: '\E922';
}
.icon-heart-alt:before {
  content: '\E96F';
}
.icon-home:before {
  content: '\E923';
}
.icon-image:before {
  content: '\E953';
}
.icon-info:before {
  content: '\E954';
}
.icon-italic:before {
  content: '\E979';
}
.icon-key:before {
  content: '\E924';
}
.icon-library:before {
  content: '\E925';
}
.icon-link:before {
  content: '\E926';
}
.icon-lock-disabled:before {
  content: '\E927';
}
.icon-lock-enabled:before {
  content: '\E928';
}
.icon-mail:before {
  content: '\E929';
}
.icon-medkit:before {
  content: '\E955';
}
.icon-menu:before {
  content: '\E92A';
}
.icon-minus:before {
  content: '\E92B';
}
.icon-minus-circle:before {
  content: '\E956';
}
.icon-minus-square:before {
  content: '\E957';
}
.icon-money:before {
  content: '\E92C';
}
.icon-monitor-clinical:before {
  content: '\E97A';
}
.icon-news:before {
  content: '\E92D';
}
.icon-notice:before {
  content: '\E92E';
}
.icon-pig:before {
  content: '\E92F';
}
.icon-pig-alt:before {
  content: '\E96A';
}
.icon-pile:before {
  content: '\E930';
}
.icon-pin:before {
  content: '\E931';
}
.icon-plus:before {
  content: '\E932';
}
.icon-plus-circle:before {
  content: '\E958';
}
.icon-plus-square:before {
  content: '\E959';
}
.icon-question:before {
  content: '\E973';
}
.icon-question-circle:before {
  content: '\E974';
}
.icon-reaction-angry:before {
  content: '\E983';
}
.icon-reaction-happy:before {
  content: '\E984';
}
.icon-reaction-laugh:before {
  content: '\E985';
}
.icon-reaction-meh:before {
  content: '\E986';
}
.icon-reaction-sad:before {
  content: '\E987';
}
.icon-redo:before {
  content: '\E95A';
}
.icon-repeat:before {
  content: '\E96B';
}
.icon-scale:before {
  content: '\E933';
}
.icon-search:before {
  content: '\E934';
}
.icon-share:before {
  content: '\E935';
}
.icon-shield:before {
  content: '\E97B';
}
.icon-sign-out:before {
  content: '\E936';
}
.icon-sitemap:before {
  content: '\E95B';
}
.icon-star:before {
  content: '\E970';
}
.icon-star-alt:before {
  content: '\E971';
}
.icon-stopwatch:before {
  content: '\E97C';
}
.icon-syringue:before {
  content: '\E95C';
}
.icon-tablets:before {
  content: '\E95D';
}
.icon-tag:before {
  content: '\E95E';
}
.icon-tags:before {
  content: '\E95F';
}
.icon-tasks:before {
  content: '\E96E';
}
.icon-temperature:before {
  content: '\E960';
}
.icon-trash:before {
  content: '\E937';
}
.icon-truck:before {
  content: '\E938';
}
.icon-turkey:before {
  content: '\E940';
}
.icon-turkey-alt:before {
  content: '\E96C';
}
.icon-umidity:before {
  content: '\E972';
}
.icon-underline:before {
  content: '\E97D';
}
.icon-upload:before {
  content: '\E939';
}
.icon-user:before {
  content: '\E93A';
}
.icon-user-alt:before {
  content: '\E93B';
}
.icon-user-id:before {
  content: '\E961';
}
.icon-user-reader:before {
  content: '\E962';
}
.icon-users:before {
  content: '\E963';
}
.icon-video:before {
  content: '\E964';
}
.icon-visibility-disabled:before {
  content: '\E93C';
}
.icon-visibility-enabled:before {
  content: '\E93D';
}
.icon-warehouse:before {
  content: '\E93E';
}
.icon-water:before {
  content: '\E965';
}
.icon-wifi:before {
  content: '\E93F';
}
.icon-wifi-disabled:before {
  content: '\E966';
}
.icon-eye:before {
  content: '\E9CE';
}
.icon-cloud-download:before {
  content: '\E9C2';
}

