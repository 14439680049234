*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body {
  font-family: 'sf_proregular', sans-serif;
  font-size: 16px;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  /* overflow: hidden; */
}

img {
  height: auto;
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #cfd2d8;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7f879a;
}

::-webkit-scrollbar-thumb:active {
  background: #596581;
}

::-webkit-scrollbar-track {
  background: #eceff1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #eceff1;
}

::-webkit-scrollbar-track:active {
  background: #cfd2d8;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.ws-pl {
  white-space: pre-line;
}
