[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'AgroPack' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** DON'T DELETE IT **/
.icon-swine:before {
  content: '\e92f';
}
.icon-broiler:before {
  content: '\e913';
}
.icon-broiler-alt:before {
  content: '\e967';
}
/** \ please, don't **/

.icon-apple-store:before {
  content: '\e941';
}
.icon-archive:before {
  content: '\e900';
}
.icon-arrow-down:before {
  content: '\e901';
}
.icon-arrow-left:before {
  content: '\e902';
}
.icon-arrow-right:before {
  content: '\e903';
}
.icon-arrow-up:before {
  content: '\e904';
}
.icon-bell-disabled:before {
  content: '\e905';
}
.icon-bell-enabled:before {
  content: '\e906';
}
.icon-bold:before {
  content: '\e975';
}
.icon-book:before {
  content: '\e907';
}
.icon-bootprint:before {
  content: '\e976';
}
.icon-bottle:before {
  content: '\e942';
}
.icon-boxes:before {
  content: '\e943';
}
.icon-bug:before {
  content: '\e977';
}
.icon-calculator:before {
  content: '\e908';
}
.icon-calendar:before {
  content: '\e909';
}
.icon-calendar-alt:before {
  content: '\e96d';
}
.icon-camera:before {
  content: '\e97e';
}
.icon-capsules:before {
  content: '\e944';
}
.icon-chart-analytics:before {
  content: '\e90a';
}
.icon-chart-bar:before {
  content: '\e90b';
}
.icon-chart-line:before {
  content: '\e90c';
}
.icon-chart-pie:before {
  content: '\e90d';
}
.icon-check:before {
  content: '\e90e';
}
.icon-check-circle:before {
  content: '\e945';
}
.icon-check-double:before {
  content: '\e988';
}
.icon-check-square:before {
  content: '\e946';
}
.icon-chevron-down:before {
  content: '\e90f';
}
.icon-chevron-left:before {
  content: '\e910';
}
.icon-chevron-right:before {
  content: '\e911';
}
.icon-chevron-up:before {
  content: '\e912';
}
.icon-chicken:before {
  content: '\e913';
}
.icon-chicken-alt:before {
  content: '\e967';
}
.icon-chlorine:before {
  content: '\e968';
}
.icon-clip:before {
  content: '\e978';
}
.icon-clipboard:before {
  content: '\e947';
}
.icon-clipboard-user:before {
  content: '\e948';
}
.icon-clock:before {
  content: '\e914';
}
.icon-close:before {
  content: '\e915';
}
.icon-close-circle:before {
  content: '\e916';
}
.icon-close-square:before {
  content: '\e949';
}
.icon-cloud-sun:before {
  content: '\e94a';
}
.icon-cog:before {
  content: '\e917';
}
.icon-comment:before {
  content: '\e918';
}
.icon-corn:before {
  content: '\e94b';
}
.icon-counter:before {
  content: '\e969';
}
.icon-disease:before {
  content: '\e97f';
}
.icon-dolly:before {
  content: '\e919';
}
.icon-download:before {
  content: '\e91a';
}
.icon-drop:before {
  content: '\e94c';
}
.icon-drop-rain:before {
  content: '\e94d';
}
.icon-edit:before {
  content: '\e91b';
}
.icon-edit-alt:before {
  content: '\e94e';
}
.icon-ellipsis-v:before {
  content: '\e91c';
}
.icon-exclamation-circle:before {
  content: '\e91d';
}
.icon-exclamation-triangle:before {
  content: '\e91e';
}
.icon-file:before {
  content: '\e94f';
}
.icon-filter:before {
  content: '\e91f';
}
.icon-filter-alt:before {
  content: '\e920';
}
.icon-flask:before {
  content: '\e950';
}
.icon-flock:before {
  content: '\e921';
}
.icon-folder:before {
  content: '\e980';
}
.icon-folder-tree:before {
  content: '\e982';
}
.icon-folders:before {
  content: '\e981';
}
.icon-globe:before {
  content: '\e951';
}
.icon-google-play:before {
  content: '\e952';
}
.icon-heart:before {
  content: '\e922';
}
.icon-heart-alt:before {
  content: '\e96f';
}
.icon-home:before {
  content: '\e923';
}
.icon-image:before {
  content: '\e953';
}
.icon-info:before {
  content: '\e954';
}
.icon-italic:before {
  content: '\e979';
}
.icon-key:before {
  content: '\e924';
}
.icon-library:before {
  content: '\e925';
}
.icon-link:before {
  content: '\e926';
}
.icon-lock-disabled:before {
  content: '\e927';
}
.icon-lock-enabled:before {
  content: '\e928';
}
.icon-mail:before {
  content: '\e929';
}
.icon-medkit:before {
  content: '\e955';
}
.icon-menu:before {
  content: '\e92a';
}
.icon-minus:before {
  content: '\e92b';
}
.icon-minus-circle:before {
  content: '\e956';
}
.icon-minus-square:before {
  content: '\e957';
}
.icon-money:before {
  content: '\e92c';
}
.icon-monitor-clinical:before {
  content: '\e97a';
}
.icon-news:before {
  content: '\e92d';
}
.icon-notice:before {
  content: '\e92e';
}
.icon-pig:before {
  content: '\e92f';
}
.icon-pig-alt:before {
  content: '\e96a';
}
.icon-pile:before {
  content: '\e930';
}
.icon-pin:before {
  content: '\e931';
}
.icon-plus:before {
  content: '\e932';
}
.icon-plus-circle:before {
  content: '\e958';
}
.icon-plus-square:before {
  content: '\e959';
}
.icon-question:before {
  content: '\e973';
}
.icon-question-circle:before {
  content: '\e974';
}
.icon-reaction-angry:before {
  content: '\e983';
}
.icon-reaction-happy:before {
  content: '\e984';
}
.icon-reaction-laugh:before {
  content: '\e985';
}
.icon-reaction-meh:before {
  content: '\e986';
}
.icon-reaction-sad:before {
  content: '\e987';
}
.icon-redo:before {
  content: '\e95a';
}
.icon-repeat:before {
  content: '\e96b';
}
.icon-scale:before {
  content: '\e933';
}
.icon-search:before {
  content: '\e934';
}
.icon-share:before {
  content: '\e935';
}
.icon-shield:before {
  content: '\e97b';
}
.icon-sign-out:before {
  content: '\e936';
}
.icon-sitemap:before {
  content: '\e95b';
}
.icon-star:before {
  content: '\e970';
}
.icon-star-alt:before {
  content: '\e971';
}
.icon-stopwatch:before {
  content: '\e97c';
}
.icon-syringue:before {
  content: '\e95c';
}
.icon-tablets:before {
  content: '\e95d';
}
.icon-tag:before {
  content: '\e95e';
}
.icon-tags:before {
  content: '\e95f';
}
.icon-tasks:before {
  content: '\e96e';
}
.icon-temperature:before {
  content: '\e960';
}
.icon-trash:before {
  content: '\e937';
}
.icon-truck:before {
  content: '\e938';
}
.icon-turkey:before {
  content: '\e940';
}
.icon-turkey-alt:before {
  content: '\e96c';
}
.icon-umidity:before {
  content: '\e972';
}
.icon-underline:before {
  content: '\e97d';
}
.icon-upload:before {
  content: '\e939';
}
.icon-user:before {
  content: '\e93a';
}
.icon-user-alt:before {
  content: '\e93b';
}
.icon-user-id:before {
  content: '\e961';
}
.icon-user-reader:before {
  content: '\e962';
}
.icon-users:before {
  content: '\e963';
}
.icon-video:before {
  content: '\e964';
}
.icon-visibility-disabled:before {
  content: '\e93c';
}
.icon-visibility-enabled:before {
  content: '\e93d';
}
.icon-warehouse:before {
  content: '\e93e';
}
.icon-water:before {
  content: '\e965';
}
.icon-wifi:before {
  content: '\e93f';
}
.icon-wifi-disabled:before {
  content: '\e966';
}
.icon-eye:before {
  content: '\e9ce';
}
.icon-cloud-download:before {
  content: '\e9c2';
}
